





















import Vue from 'vue';
import { SearchOrder } from '@lordly/models2/interfaces/gql';
import { ActionPayload } from '@/interfaces';
import { SearchState } from '@/store/search/state';

export default Vue.extend({
  name: 'Search-Reorder-Component',
  data: () => {
    return {
      options: [
        {
          text: 'Default',
          order: 'Default',
        },
        {
          text: 'Deposit (Low to High)',
          order: 'DepositMin',
        },
        {
          text: 'Deposit (High to Low)',
          order: 'DepositMax',
        },
        {
          text: 'Rent (Low to High)',
          order: 'RentMin',
        },
        {
          text: 'Rent (High to Low)',
          order: 'RentMax',
        },
        {
          text: 'Bedrooms (Low to High)',
          order: 'BedMin',
        },
        {
          text: 'Bedrooms (High to Low)',
          order: 'BedMax',
        },
      ] as ReorderButton[],
    };
  },
  computed: {
    currentOrder (): string {
      return (this.$store.state.search as SearchState).inquiry.order;
    },
  },
  methods: {
    invokeOrderBy (option: ReorderButton) {
      this.$store.dispatch('search/actionFakeLoading');
      this.$vuex.MutateStore(this, 'search', 'inquiry.order', option.order);
      this.$emit('search');
    },
  },
});

interface ReorderButton {
  text: string;
  order: SearchOrder;
}
