
































































































































































































import Vue from 'vue';
import { RootStore } from '@/store';
import { ActionPayload } from '@/interfaces';
import { QSelectOptions } from '@/interfaces/quasar';
import { debounce } from 'quasar';

export default Vue.extend({
  name: 'Primary-Filter-Component',
  props: {
    secondaryFilter: {
      type: Boolean,
      required: true,
      default: false,
    },
    savedFilter: {
      type: Boolean,
      required: true,
      default: false,
    },
    reorderOptions: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data () {
    return {
      roomOptions: [] as QSelectOptions[],
      rentOptions: [] as QSelectOptions[],
      showMobileMenu: false,
      debounceSearch: () => { return; },
    };
  },
  computed: {
    possibleCities: {
      get (): string[] {
        return (this.$store.state as RootStore).possibleCities;
      },
    },
    area: {
      get (): string {
        return (this.$store.state as RootStore).search!.form.area;
      },
      set (value: string) {
        this.updateVuexStore('form.area', value);
      },
    },
    bedMin: {
      get (): number {
        return (this.$store.state as RootStore).search!.form.bedMin;
      },
      set (value: number) {
        this.updateVuexStore('form.bedMin', value);
      },
    },
    bedMax: {
      get (): number {
        return (this.$store.state as RootStore).search!.form.bedMax;
      },
      set (value: number) {
        this.updateVuexStore('form.bedMax', value);
      },
    },
    weeklyRentMin: {
      get (): number {
        return (this.$store.state as RootStore).search!.form.rentMin;
      },
      set (value: number) {
        this.updateVuexStore('form.rentMin', value);
      },
    },
    weeklyRentMax: {
      get (): number {
        return (this.$store.state as RootStore).search!.form.rentMax;
      },
      set (value: number) {
        this.updateVuexStore('form.rentMax', value);
      },
    },
  },
  watch: {},
  created () {
    this.createRoomRange();
    this.createRentRange();
    // Initialise search
    this.debounceSearch = debounce(() => {
      this.search();
    }, 250);
  },
  // Methods
  methods: {
    search () {
      if (this.showMobileMenu) {
        this.showMobileMenu = false;
      }
      // Replace area with known city
      this.findKnownCity();
      // Generate payload
      const payload: ActionPayload = {
        component: this,
      };
      this.$store.dispatch('search/actionSearchProperties', payload);
    },
    updateVuexStore (key: string, value: string | number) {
      this.$vuex.MutateStore(this, 'search', key, value);
    },
    createRoomRange () {
      // Start: 1, End: 10
      for (let count: number = 1; count <= 10; count++) {
        // Create Label Objects
        let optionObject: QSelectOptions = {
          label: String(count),
          value: count,
          description: 'bed(s)',
        };
        // Add to the array
        this.roomOptions.push(optionObject);
      }
    },
    createRentRange () {
      // Start: £0, End: £200
      for (let count: number = 10; count <= 200; count += 10) {
        // Create label objects
        let optionObject: QSelectOptions = {
          label: '£' + String(count), // Needs to be a string
          description: 'per week',
          value: count,
        };
        // Add to the array
        this.rentOptions.push(optionObject);
      }
    },
    findKnownCity () {
      if (this.possibleCities.length > 0) {
        // Create regex to sanitize string
        const regexSanitizeString: RegExp = new RegExp(/[\s{1,}]/gm);
        for (const idx in this.possibleCities) {
          if (this.possibleCities[idx]) {
            const cityResult: string = this.possibleCities[idx].replace(regexSanitizeString, '').toLowerCase();
            const cityInquiry: string = this.area.replace(regexSanitizeString, '').toLowerCase();

            if (cityResult === cityInquiry) {
              this.area = this.possibleCities[idx];
            }
          }
        }
      }
    },
  },
});
