




















































































































































import Vue from 'vue';
import { RootStore } from '@/store';
import { SearchType, SearchEnsuite } from '@/store/search/state';
import { QSelectOptions } from '@/interfaces/quasar';
import { ActionPayload } from '@/interfaces';

export default Vue.extend({
  name: 'Secondary-Filter-Component',
  data () {
    return {
      depositOptions: [] as QSelectOptions[],
      durationOptions: [] as QSelectOptions[],
      typeOptions: [
        {
          label: 'Any',
          value: '%',
        },
        {
          label: 'House',
          value: 'House',
        },
        {
          label: 'Flat',
          value: 'Flat',
        },
        {
          label: 'Halls',
          value: 'Halls',
        },
      ] as QSelectOptions[],
      toiletOptions: [] as QSelectOptions[],
      ensuiteOptions: [
        {
          label: 'Any',
          value: '%',
        },
        {
          label: 'Yes',
          value: 'Yes',
        },
        {
          label: 'No',
          value: 'No',
        },
        {
          label: 'Mixed',
          value: 'Mixed',
        },
      ] as QSelectOptions[],
    };
  },
  computed: {
    depositMin: {
      get (): number {
        return (this.$store.state as RootStore).search!.form.depositMin;
      },
      set (value: number) {
        this.updateVuexStore('form.depositMin', value);
      },
    },
    depositMax: {
      get (): number {
        return (this.$store.state as RootStore).search!.form.depositMax;
      },
      set (value: number) {
        this.updateVuexStore('form.depositMax', value);
      },
    },
    duration: {
      get (): number {
        return (this.$store.state as RootStore).search!.form.duration;
      },
      set (value: number) {
        this.updateVuexStore('form.duration', value);
      },
    },
    type: {
      get (): SearchType {
        return (this.$store.state as RootStore).search!.form.type;
      },
      set (value: SearchType) {
        this.updateVuexStore('form.type', value);
      },
    },
    toiletMin: {
      get (): number {
        return (this.$store.state as RootStore).search!.form.toiletMin;
      },
      set (value: number) {
        this.updateVuexStore('form.toiletMin', value);
      },
    },
    toiletMax: {
      get (): number {
        return (this.$store.state as RootStore).search!.form.toiletMax;
      },
      set (value: number) {
        this.updateVuexStore('form.toiletMax', value);
      },
    },
    ensuite: {
      get (): SearchEnsuite {
        return (this.$store.state as RootStore).search!.form.ensuite;
      },
      set (value: SearchEnsuite) {
        this.updateVuexStore('form.ensuite', value);
      },
    },
  },
  created () {
    this.createDepositRange();
    this.createDurationRange();
    this.createdToiletRange();
  },
  methods: {
    updateVuexStore (key: string, value: string | number) {
      this.$vuex.MutateStore(this, 'search', key, value);
    },
    search () {
      this.$emit('close');
      const payload: ActionPayload = {
        component: this,
      };
      this.$store.dispatch('search/actionSearchProperties', payload);
    },
    createDepositRange () {
      // Start: £0, End: £1000
      for (let count: number = 0; count <= 1000; count += 50) {
        // Create label objects
        const optionObject: QSelectOptions = {
          label: '£' + String(count), // Needs to be a string
          description: 'per person',
          value: count,
        };
        // Add to the array
        this.depositOptions.push(optionObject);
      }
    },
    createdToiletRange () {
      // Start: 1, End: 10
      for (let count: number = 1; count <= 10; count++) {
        // Create label objects
        let optionObject: QSelectOptions = {
          label: String(count), // Needs to be a string
          value: count,
        };
        // Add to the array
        this.toiletOptions.push(optionObject);
      }
    },
    createDurationRange () {
      // Start: 1, End: 24
      for (let count: number = 1; count <= 24; count++) {
        // Create label objects
        let optionObject: QSelectOptions = {
          label: String(count) + ' months', // Needs to be a string
          value: count,
        };
        // Add to the array
        this.durationOptions.push(optionObject);
      }
    },
  },
});
