

























































































import Vue from 'vue';
import PrimaryFilter from '@/blueprint/components/search/PrimaryFilter.vue';
import SecondaryFilter from '@/blueprint/components/search/SecondaryFilter.vue';
import Loading from '@/blueprint/components/ui/Loading.vue';
import Result from '@/blueprint/components/search/Result.vue';
import NoResult from '@/blueprint/components/search/NoResults.vue';
import Reorder from '@/blueprint/components/search/Reorder.vue';

import { ClickAway } from '@/assets/mixins';

import { SearchState } from '@/store/search/state';
import { Property } from '@lordly/models2/interfaces/models/Property';
import { ActionPayload } from '@/interfaces';
import { NotifyAPIOptions } from '@/interfaces/quasar';
import {} from 'googlemaps';

export default Vue.extend({
  name: 'Search-Page',
  components: {
    'primary-filter': PrimaryFilter,
    'secondary-filter': SecondaryFilter,
    'loading': Loading,
    'search-result': Result,
    'no-results': NoResult,
    'reorder-results': Reorder,
  },
  data () {
    return {
      results: [] as Array<Partial<Property>>,
      showSavedResults: false,
      showSecondaryFilters: false,
      showReorderOptions: false,
      // Google Maps
      resultMarkers: {} as Record<string, google.maps.Marker>,
      infoWindow: {} as google.maps.InfoWindow,
      lastActivePropertyId: '',
      currentPageWatcher: () => { return; },
    };
  },
  computed: {
    searchArea: {
      get (): string {
        return (this.$store.state.search as SearchState).form.area;
      },
      set (value: string): void {
        this.updateVuexStore('form.area', value);
      },
    },
    searchLoading: {
      get (): boolean {
        return (this.$store.state.search as SearchState).inquiry.loading;
      },
    },
    searchResults: {
      get (): Array<Partial<Property>> {
        return (this.$store.state.search as SearchState).inquiry.results;
      },
    },
    savedResults: {
      get (): Array<Partial<Property>> {
        return (this.$store.state.search as SearchState).inquiry.resultsSaved;
      },
    },
    searchedAlready: {
      get (): boolean {
        return (this.$store.state.search as SearchState).inquiry.firstInquiryInvoked;
      },
      set (value: boolean) {
        this.$vuex.MutateStore(this, 'search', 'inquiry.firstInquiryInvoked', value);
      },
    },
    searchCurrentPage: {
      get (): number {
        return (this.$store.state.search as SearchState).inquiry.currentPage;
      },
      set (value: number) {
        this.$vuex.MutateStore(this, 'search', 'inquiry.currentPage', value);
      },
    },
    searchTotalPages: {
      get (): number {
        return (this.$store.state.search as SearchState).inquiry.totalPages;
      },
    },
    noResults (): boolean {
      if (this.searchResults && this.searchResults.length === 0 && this.searchedAlready) {
        return true;
      }
      return false;
    },
  },
  watch: {
    results () {
      if (window.innerWidth >= 1200) {
        this.mapResults();
      }
    },
    searchResults () {
      if (!this.showSavedResults) {
        this.results = this.searchResults;
      }
    },
    savedResults () {
      if (this.showSavedResults) {
        this.results = this.savedResults;
      }
    },
  },
  mounted () {
    if (this.$route.params.area) {
      this.searchArea = this.$route.params.area;
      this.search();
    }
    // Load saved properties from local storage
    this.$store.commit('search/LoadFromLocalStorage');
    // Default set
  },
  methods: {
    updateVuexStore (key: string, value: string | number) {
      this.$vuex.MutateStore(this, 'search', key, value);
    },
    toggleSecondaryFilters () {
      this.showSecondaryFilters = !this.showSecondaryFilters;
    },
    search () {
      // Close reorder options if open
      if (this.showReorderOptions) {
        this.showReorderOptions = false;
      }
      // Destory current watcher
      this.destoryPaginationWatcher();
      // Create payload for action
      const payload: ActionPayload = {
        component: this,
      };
      this.$store.dispatch('search/actionSearchProperties', payload).then(() => {
        // Recreate watcher once action is complete
        this.createPaginationWatcher();
      }).catch((e) => {
        console.error(e);
      });
    },
    loadSavedResults () {
      this.$store.dispatch('search/actionFakeLoading');
      if (this.showSavedResults) {
        this.loadSearchResults();
      } else {
        this.results = this.savedResults;
        this.showSavedResults = true;
      }
    },
    loadSearchResults () {
      this.results = this.searchResults;
      this.showSavedResults = false;
    },
    mapResults () {
      // Create map bounderies object
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
      // Get the map element
      const domElement: Element = this.$refs.searchResultMap as Element;
      // Set the map options
      const mapOptions: Record<string, any> = {
        zoom: 9,
        scrollwheel: true,
        scaleControl: true,
        fullscreenControl: true,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_CENTER,
        },
        gestureHandling: 'cooperative',
      };
      // Create the map
      const map: google.maps.Map = new google.maps.Map(domElement, mapOptions);
      // For each search result, do something...
      this.resultMarkers = {} as Record<string, google.maps.Marker>; // Empty the marker object, first...
      // Create infowindow for markers - SINGLE INSTANCE
      this.infoWindow = new google.maps.InfoWindow();
      // Close it to set the map to null (IMPORTANT)
      this.infoWindow.close();
      // Add event listener for infowindow on close
      google.maps.event.addListener(this.infoWindow, 'closeclick', () => {
        this.lowlightResultMarker(this.lastActivePropertyId);
      });
      // Create google maps markers and place them on the map
      for (const idx in this.results) {
        if (this.results[idx]) {
          const result: Partial<Property> = this.results[idx]; // Get the property
          const latlng: any = { lat: result.address!.latlng.lat, lng: result.address!.latlng.lng }; // Get the location of property
          // Create a marker for property
          const marker: google.maps.Marker = new google.maps.Marker({
            position: latlng,
            title: result.address!.line1,
            icon: '/img/app/icons/result-default.png',
            clickable: true,
          });
          // Populate infowindow with property details
          const infoWindowContent: string = `<div class="result-infocard" id="` + result.id + `">
                                      <h5 class="infocard-title">` + result.address!.line1 + `</h5>
                                      <span class="infocard-city">` + result.address!.city + `</span>
                                      <div class="infocard-details row">
                                        <div class="col-6">
                                          <span class="details-title">Bedrooms:</span>
                                          <span>` + result.details!.bedroom + `</span>
                                        </div>
                                        <div class="col-6">
                                          <span class="details-title">Rent Per Week:</span>
                                          <span>£` + result.rent!.computed.weekly + `</span>
                                        </div>
                                        <div class="col-12">
                                          <span class="details-title">Deposit Per Person:</span>
                                          <span>£` + result.deposit!.computed.perperson + `</span>
                                        </div>
                                      </div>
                                    </div>`;
          // Add event listener for each marker to open its respective info windows
          marker.addListener('click', () => {
            this.infoWindow.close(); // Close previous info window
            this.infoWindow.setContent(infoWindowContent); // Set the contsent
            this.infoWindow.open(map, marker); // Open the window on the marker
            this.focusResult(result.id!); // Focus on result
          });
          // Set the bounderies of the results map
          bounds.extend(marker.getPosition()!);
          // Set the marker on the map
          marker.setMap(map);
          // Add marker object to resultsMarker object store
          this.resultMarkers[result.id!] = marker;
        }
      }
      // Fit the map to the bounds
      map.fitBounds(bounds);
      // Resizes the map after the boundary is fit so everything is in view.
      google.maps.event.addListenerOnce(map, 'idle', function () {
        if (map.getZoom() > 16) {
          map.setZoom(15);
        }
      });
    },
    focusResult (id: string) {
      if (this.lastActivePropertyId) {
        this.lowlightResultMarker(this.lastActivePropertyId);
      }
      this.lastActivePropertyId = id;
      this.hightlightResultMarker(id);
    },
    lowlightResultMarker (id: string) {
      if (window.innerWidth >= 1200) {
        if (this.resultMarkers[id]) {
          // Set marker off
          const marker: google.maps.Marker = this.resultMarkers[id];
          marker.setIcon('/img/app/icons/result-default.png');
        }
      }
    },
    hightlightResultMarker (id: string) {
      if (window.innerWidth >= 1200) {
        if (this.resultMarkers[id]) {
          // Set marker on
          const marker: google.maps.Marker = this.resultMarkers[id];
          marker.setIcon('/img/app/icons/result-active.png');
        }
      }
    },
    toggleReorderOptions () {
      this.showReorderOptions = !this.showReorderOptions;
      if (this.showReorderOptions) {
        ClickAway(this, 'reorderResults', 'delayedHideReorder');
      }
    },
    delayedHideReorder () {
      // BugFix: The delay allows the toggle to work such that this action is fired off 10ms later preventing it from going true -> false -> true again
      setTimeout(() => {
        this.showReorderOptions = false;
      }, 10);
    },
    showSearchOption (option: string) {
      switch (option) {
        case 'secondary':
          this.toggleSecondaryFilters();
          // Close other options
          this.showReorderOptions = false;
          this.showSavedResults = false;
          break;
        case 'results':
          this.loadSavedResults();
          // Close other options
          this.showReorderOptions = false;
          this.showSecondaryFilters = false;
          break;
        case 'reorder':
          this.toggleReorderOptions();
          // Close other options
          this.showSecondaryFilters = false;
          this.showSavedResults = false;
          break;
        default:
          break;
      }
    },
    createPaginationWatcher () {
      // Setup watcher for pagination
      this.currentPageWatcher = this.$watch('searchCurrentPage', () => {
        this.search();
      });
    },
    destoryPaginationWatcher () {
      this.currentPageWatcher();
    },
  },
});
