








































import Vue from 'vue';
import { CopyToClipBoard } from '@/assets/mixins';
import { SearchState } from '@/store/search/state';

export default Vue.extend({
  name: 'Search-No-Results-Component',
  data: () => {
    return {
      urlCopyMessage: 'Copy URL to Clipboard',
      urlCopyStatus: false,
    };
  },
  computed: {
    searchResultExists: {
      get (): boolean {
        return (this.$store.state.search as SearchState).inquiry.resultsExists;
      },
    },
  },
  methods: {
    copyURL () {
      this.urlCopyStatus = CopyToClipBoard(window.location.origin);
      if (this.urlCopyStatus) {
        this.urlCopyMessage = 'Copied to Clipboard';
      } else {
        this.urlCopyMessage = 'Ooops, try again...';
        // Reset button after 5 seconds
        setTimeout(() => {
          this.urlCopyMessage = 'Copy URL to Clipboard';
        }, 5000);
      }
    },
    openDefaultMailClient () {
      const emailSubject: string = 'Have you heard off Lordly.app?';
      const emailBody: string = 'Hi,%0D%0A%0D%0AI was looking for student houses and noticed your property was not listed on Lordly.app.%0D%0A%0D%0AWant to find out more?%0D%0A%0D%0AVisit: https://www.lordly.app%0D%0A%0D%0A Kind Regards%0D%0A%0D%0A';
      window.location.href = 'mailto:?subject=' + emailSubject + '&body=' + emailBody;
    },
  },
});
