






















































































































































import Vue from 'vue';
import {} from 'googlemaps';
import { Property } from '@lordly/models2/interfaces/models/Property';
import { Blob } from '@lordly/models2/interfaces';
import { SearchState } from '@/store/search/state';

export default Vue.extend({
  name: 'Search-Result-Component',
  components: {},
  inject: [],
  mixins: [],
  props: {
    property: {
      type: Object as () => Partial<Property>,
      default: () => {
        return {
          id: '',
          images: [] as Blob[],
          address: {
            line1: '',
            city: '',
            postcode: '',
            latlng: {
              lat: '',
              lng: '',
            },
          },
          details: {
            bedroom: '',
            toilet: '',
            type: '',
            ensuite: '',
          },
          dates: {
            tenancyStart: '',
            tenancyEnd: '',
            duration: {
              monthsRounded: '',
            },
          },
          rent: {
            annually: '',
            computed: {
              weekly: '',
              monthly: '',
              yearly: '',
            },
          },
          deposit: {
            total: '',
            computed: {
              perperson: '',
            },
          },
          computed: {
            weeklyRent: null,
            monthlyRent: null,
            yearlyRent: null,
            duration: null,
            depositPerPerson: null,
          },
        };
      },
      required: true,
    },
    portfolio: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => {
    return {
      active: false,
      slide: 0,
      images: ['/img/app/icons/placeholder-property-thumbnail.png'],
      saved: false,
      // Phase 2
      scrolled: false,
      mapped: false,
      observer: {} as IntersectionObserver,
      // Analytics
      lgaTracked: false,
    };
  },
  computed: {
    savedProperties (): Record<string, number> {
      return (this.$store.state.search as SearchState).inquiry.resultsSavedDictionary;
    },
  },
  mounted () {
    // Create intersection observers for lazy loading
    const threshold: number = 0.1;
    const options: Record<string, any> = {
      root: this.$parent.$refs.searchResultsContainer,
      rootMargin: '16px',
      threshold,
    };
    this.observer = new IntersectionObserver((entry, observer) => {
      // Check as when instantiated the function fires straight away
      if (entry[0].intersectionRatio > threshold) {
        this.constructImages();
        this.observer.unobserve(this.$refs.resultCard as Element);
        delete this.observer; // Mark for GC to reclaim memory
      }
    }, options);
    this.observer.observe(this.$refs.resultCard as Element);
    // Check if saved
    this.checkIfSaved();
  },
  updated () {
    if (this.active) {
      if (window.innerWidth >= 992 && !this.portfolio && !this.scrolled) {
        // Animate scroll to bottom
        this.animateScroll(500);
        this.scrolled = true;
      }
      if (!this.mapped) {
        this.mapProperty();
        this.mapped = true;
      }
    } else {
      this.mapped = false;
    }
  },
  // Methods
  methods: {
    invokePhase2 () {
      if (!this.active) {
        this.active = true;
        // Track evolution
        if (!this.lgaTracked) {
          this.$lga.TrackEvolution(this.property.id!, this.property.address!.city);
          this.lgaTracked = true;
        }
      }
    },
    mapProperty () {
      // Create location object
      let latlng: any = { lat: this.property!.address!.latlng.lat, lng: this.property!.address!.latlng.lng };
      // Create the marker
      const marker: google.maps.Marker = new google.maps.Marker({
        position: latlng,
        title: this.property!.address!.line1,
        icon: '/img/app/icons/result-default.png',
        clickable: false,
      });
      // Set the map view options
      let mapOptions: any = {
        center: latlng,
        zoom: 14,
        scrollwheel: false,
        scaleControl: false,
        fullscreenControl: false,
        zoomControl: true,
      };
      // Get map element reference
      const domElement: Element = this.$refs.resultMap as Element;
      // Create map
      const map: google.maps.Map =  new google.maps.Map(domElement, mapOptions);
      // Set marker
      marker.setMap(map);
    },
    animateScroll (duration: number) {
      const domElement: Element = this.$refs.resultCard as Element;
      const start: number = domElement.scrollTop;
      const end: number = domElement.scrollHeight;
      const change: number = end - start;
      const increment: number = 25;
      // Calculate time pased
      const easeInOut: (currentTime: number, start: number, change: number, duration: number) => number = (currentTime: number, start: number, change: number, duration: number) => {
        // By Robert Penner (https://medium.com/@heatherbooker/how-to-auto-scroll-to-the-bottom-of-a-div-415e967e7a24)
        currentTime /= duration / 2;
        if (currentTime < 1) {
          return change / 2 * currentTime * currentTime + start;
        }
        currentTime -= 1;
        return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
      };
      // Animate the scroll
      const animate: (elapsedTime: number) => void = (elapsedTime: number) => {
        elapsedTime += increment;
        const position: number = easeInOut(elapsedTime, start, change, duration);
        domElement.scrollTop = position;
        if (elapsedTime < duration) {
          setTimeout(function () {
            animate(elapsedTime);
          }, increment);
        }
      };
      // Done
      animate(0);
    },
    constructImages () {
      if (this.property!.images!.length > 0) {
        this.images = this.property!.images!.map((image) => {
          return image.url;
        });
      }
    },
    toggleSave () {
      // If saved, unsave, else save
      if (this.saved) {
        this.$store.commit('search/UnsaveResult', this.property.id!);
        this.saved = false;
      } else {
        this.$store.commit('search/SaveResult', this.property.id!);
        this.saved = true;
      }
    },
    checkIfSaved () {
      // Extract dictionary
      const knownSavedProperties: Record<string, number> = (this.$store.state.search as SearchState).inquiry.resultsSavedDictionary;
      // Determine if property in dictionary
      if (knownSavedProperties[this.property.id!] !== undefined) {
        this.saved = true;
      }
    },
    highlightResult () {
      if (window.innerWidth > 1200 && !this.portfolio) {
        this.$emit('highlight', this.property.id);
      }
    },
    lowlightResult () {
      if (window.innerWidth > 1200 && !this.portfolio) {
        this.$emit('lowlight', this.property.id);
      }
    },
    showMoreInfo () {
      this.$router.push('/moreinfo/' + this.property.id + '/' + this.property.address!.city);
    },
  },
});
